import React, { useCallback, useLayoutEffect, useMemo, type ReactNode } from 'react';
import { styled } from '@compiled/react';
import noop from 'lodash/noop';
import BulletListIcon from '@atlaskit/icon/core/migration/list-bulleted--bullet-list';
import DetailViewIcon from '@atlaskit/icon/core/migration/sidebar-right--detail-view';
import LayoutTwoColumnsSidebarLeftIcon from '@atlaskit/icon/core/layout-two-columns-sidebar-left';
import { Box, Text } from '@atlaskit/primitives';
import SpreadsheetIcon from '@atlaskit/icon/core/spreadsheet';
import { token } from '@atlaskit/tokens';
import Tooltip from '@atlaskit/tooltip';
import Shortcuts from '@atlassian/jira-common-components-keyboard-shortcuts/src/shortcuts/index.tsx';
import { expVal } from '@atlassian/jira-feature-experiments';
import type { IntlShapeV2 as Intl } from '@atlassian/jira-intl/src/v2/types.tsx';
import { useIntlV2 as useIntl } from '@atlassian/jira-intl/src/v2/use-intl.tsx';
import { isCustomFilter } from '@atlassian/jira-issue-navigator-actions-common/src/utils/filters/index.tsx';
import { NinChangeboardingTourTarget } from '@atlassian/jira-issue-navigator-changeboarding/src/controllers/enable-nin-changeboarding/index.tsx';
import { cardKeys as spotlightCardKeys } from '@atlassian/jira-issue-navigator-changeboarding/src/controllers/spotlight-coordination/constants.tsx';
import SpotlightTourCard from '@atlassian/jira-issue-navigator-changeboarding/src/ui/spotlight-tour-card/index.tsx';
import SpotlightTourTarget, {
	type Props as SpotlightTourTargetProps,
} from '@atlassian/jira-issue-navigator-changeboarding/src/ui/spotlight-tour-target/index.tsx';
import { getWillShowNav4 } from '@atlassian/jira-navigation-apps-sidebar-nav4-rollout-core/src/common/utils/get-will-show-nav4/index.tsx';
import { fireUIAnalytics, useAnalyticsEvents } from '@atlassian/jira-product-analytics-bridge';
import { toIssueKey } from '@atlassian/jira-shared-types/src/general.tsx';
import ToggleButtons from '@atlassian/jira-toggle-buttons/src/index.tsx';
import { isVisualRefreshEnabled } from '@atlassian/jira-visual-refresh-rollout/src/feature-switch/index.tsx';
import { views } from '../../common/constants.tsx';
import type { View } from '../../common/types.tsx';
import {
	convertToIssueNavigatorId,
	convertFilterIdToIssueNavigatorId,
	convertToView,
} from '../../common/utils/index.tsx';
import { markOnce, marks } from '../../common/utils/performance-analytics.tsx';
import { useSelectedIssueKey } from '../../controllers/selected-issue/facade.tsx';
import { useSelectedViewState } from '../../controllers/selected-view-state/index.tsx';
import messages from './messages.tsx';

type Props = {
	view: View;
	onToggle: (view: View, isKeyboardShortcut: boolean) => boolean;
	intl: Intl;
};

const KeyboardShortcuts = ({ view, onToggle, intl: { formatMessage } }: Props) => {
	const isDetailView = view === views.detail;
	return (
		<Shortcuts
			keyMap={{
				t: {
					callback: () => {
						const newView = isDetailView ? views.list : views.detail;
						onToggle(newView, true);
					},
					label: <>{formatMessage(isDetailView ? messages.listText : messages.detailText)}</>,
				},
			}}
		/>
	);
};

export const ViewSwitcher = ({ view, onToggle, intl }: Props) => {
	const { formatMessage } = intl;
	const testIdPrefix = 'issue-navigator.ui.refinement-bar.view-switcher.toggle-button.';

	let listLabel: ReactNode | string = useMemo(
		() => (
			<IconLabel>
				<Box as="span">
					<Text size="UNSAFE_small" weight="semibold">
						{formatMessage(messages.listOptionLabel)}
					</Text>
				</Box>
				<BulletListIcon label="" LEGACY_size="small" />
			</IconLabel>
		),
		[formatMessage],
	);
	let detailLabel: ReactNode | string = useMemo(
		() => (
			<IconLabel>
				<Box as="span">
					<Text size="UNSAFE_small" weight="semibold">
						{formatMessage(messages.detailOptionLabel)}
					</Text>
				</Box>
				<DetailViewIcon label="" LEGACY_size="small" />
			</IconLabel>
		),
		[formatMessage],
	);

	if (getWillShowNav4()) {
		// eslint-disable-next-line react-hooks/rules-of-hooks
		listLabel = useMemo(
			() => (
				<Text size="UNSAFE_small" weight="semibold">
					{formatMessage(messages.listOptionLabel)}
				</Text>
			),
			[formatMessage],
		);
		// eslint-disable-next-line react-hooks/rules-of-hooks
		detailLabel = useMemo(
			() => (
				<Text size="UNSAFE_small" weight="semibold">
					{formatMessage(messages.detailOptionLabel)}
				</Text>
			),
			[formatMessage],
		);
	}

	if (isVisualRefreshEnabled()) {
		// eslint-disable-next-line react-hooks/rules-of-hooks
		listLabel = useMemo(
			() => (
				<Tooltip content={formatMessage(messages.listOptionLabel)}>
					<Box paddingInline="space.050">
						<SpreadsheetIcon label={formatMessage(messages.listOptionLabel)} />
					</Box>
				</Tooltip>
			),
			[formatMessage],
		);
		// eslint-disable-next-line react-hooks/rules-of-hooks
		detailLabel = useMemo(
			() => (
				<Tooltip content={formatMessage(messages.detailOptionLabel)}>
					<Box paddingInline="space.050">
						<LayoutTwoColumnsSidebarLeftIcon label={formatMessage(messages.detailOptionLabel)} />
					</Box>
				</Tooltip>
			),
			[formatMessage],
		);
	}

	const options = useMemo(
		() => [
			{
				id: views.list,
				label: listLabel,
				testId: `${testIdPrefix}${views.list}`,
			},
			{
				id: views.detail,
				label: detailLabel,
				testId: `${testIdPrefix}${views.detail}`,
			},
		],
		[listLabel, detailLabel],
	);

	return (
		<>
			<KeyboardShortcuts view={view} onToggle={onToggle} intl={intl} />
			<ToggleButtons
				label={formatMessage(messages.toggleButtonsLabel)}
				isCompact={isVisualRefreshEnabled()}
				options={options}
				selectedOption={view}
				onChange={(newView) => {
					// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
					onToggle(newView as View, false);
				}}
			/>
		</>
	);
};

ViewSwitcher.defaultProps = {
	onToggle: noop,
};

export const ViewSwitcherWithState = ({ filterId }: { filterId?: string }) => {
	markOnce(marks.PAGE_ACTIONS_VIEW_SWITCHER_START);
	useLayoutEffect(() => {
		markOnce(marks.PAGE_ACTIONS_VIEW_SWITCHER_END);
	}, []);

	const selectedIssueKey = useSelectedIssueKey();

	const [{ view }, { setView }] = useSelectedViewState();
	const intl = useIntl();
	const { createAnalyticsEvent } = useAnalyticsEvents();
	const handleOnToggle = useCallback(
		(newView: View, isKeyboardShortcut: boolean) => {
			// When toggling to detail view we want to update the issue key in the URL to match the currently selected
			// issue. When toggling to list view we want to discard the issue key from the URL.
			const newIssueKey = newView === views.detail ? selectedIssueKey : toIssueKey('');

			let resolvedNewView = convertToIssueNavigatorId(newView);

			if (newView === views.list && filterId && isCustomFilter(filterId)) {
				resolvedNewView = convertFilterIdToIssueNavigatorId(filterId);
			}

			setView(resolvedNewView, newIssueKey);

			const analyticsEvent = createAnalyticsEvent({
				action: 'clicked',
				actionSubject: 'button',
			});

			fireUIAnalytics(analyticsEvent, 'switchIssueNavigatorView', {
				keyboardShortcut: isKeyboardShortcut,
				switchToView: newView,
			});

			return true;
		},
		[selectedIssueKey, filterId, setView, createAnalyticsEvent],
	);

	const children = (
		<NinChangeboardingTourTarget engagementId="nin.view-switcher">
			<ViewSwitcher view={convertToView(view)} onToggle={handleOnToggle} intl={intl} />
		</NinChangeboardingTourTarget>
	);

	const renderSpotlightCard: SpotlightTourTargetProps['renderSpotlightCard'] = ({
		onEndTour,
		onSetActiveCard,
	}) => (
		<SpotlightTourCard
			cardKey={spotlightCardKeys.GLOBAL_SCOPE_VIEW_SWITCHER}
			dialogWidth={272}
			dialogPlacement="bottom right"
			actions={
				view === 'detail'
					? [
							{
								onClick: () => {
									onEndTour();
								},
								text: intl.formatMessage(messages.done),
							},
						]
					: [
							{
								onClick: () => {
									onSetActiveCard(spotlightCardKeys.GLOBAL_SCOPE_COLUMN_PICKER);
								},
								text: intl.formatMessage(messages.next),
							},
							{
								appearance: 'subtle',
								onClick: () => {
									onEndTour();
								},
								text: intl.formatMessage(messages.skip),
							},
						]
			}
			heading={intl.formatMessage(messages.tourHeading)}
			target="nin.spotlight.view-switcher"
			targetBgColor={token('elevation.surface')}
			targetRadius={3}
			messageId="issue-navigator.ui.view-switcher.spotlight-tour-card.nin.spotlight.view-switcher.spotlight"
			messageType="transactional"
		>
			{intl.formatMessage(
				expVal('issue-terminology-refresh-m2-replace', 'isEnabled', false)
					? messages.tourBodyIssueTermRefresh
					: messages.tourBody,
			)}
		</SpotlightTourCard>
	);

	return (
		<SpotlightTourTarget
			name="nin.spotlight.view-switcher"
			renderSpotlightCard={renderSpotlightCard}
		>
			{children}
		</SpotlightTourTarget>
	);
};

export default ViewSwitcherWithState;

// clean up this styled element with getWillShowNav4
// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const IconLabel = styled.span(
	{
		display: 'flex',
		alignItems: 'center',
	},
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles
	() =>
		// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values
		isVisualRefreshEnabled()
			? { gap: token('space.050') }
			: {
					'*': {
						marginRight: token('space.050'),

						'&:last-child': {
							marginRight: 0,
						},
					},
				},
);
